<template>
  <div v-if="userWalletTransactionsData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="6">{{ $t("UserWalletTransactions.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.type") }}</th>
          <th class="cell-lg">{{ $t("general.value") }}</th>
          <th>{{ $t("PaymentMethods.type") }}</th>
          <th class="cell-lg">{{ $t("notes") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(userWalletTransaction, index) in userWalletTransactionsData"
          :key="index"
        >
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="
                fullPathFileFromServer(
                  userWalletTransaction.userWalletTransactionImagePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(userWalletTransaction.fullCode) }}</td>
          <td>
            {{
              isDataExist(
                userWalletTransaction.userWalletTransactionTypeNameCurrent
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                userWalletTransaction.userWalletTransactionValueWithCurreny
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                userWalletTransaction.userWalletTransactionMethodNameCurrent
              )
            }}
          </td>
          <td>
            {{ isDataExist(userWalletTransaction.userWalletTransactionNotes) }}
          </td>

          <td class="fmenu-item-container">
            <FloatingMenu>
              <!-- <li>
                <button
                  @click="
                    setUserWalletTransactionData(userWalletTransaction);
                    openBottomSheet('UserWalletTransactionInfo');
                  "
                  :title="$t('info')"
                >
                  <img src="@/assets/images/info.svg" />
                </button>
              </li> -->
              <li>
                <button
                  @click="
                    setUserWalletTransactionData(userWalletTransaction);
                    openBottomSheet('UserWalletTransactionQRCode');
                  "
                  :title="$t('general.qrCode')"
                >
                  <img src="@/assets/images/qr-code.svg" />
                </button>
              </li>

              <li>
                <button
                  @click="
                    setUserWalletTransactionData(userWalletTransaction);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../../utils/functions";

import FloatingMenu from "./../../../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../../../utils/generalMixin";

export default {
  name: "UserWalletTransactionsTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["userWalletTransactionsData", "filterData", "defaultImg"],
  methods: {
    setUserWalletTransactionData(userWalletTransaction) {
      this.$emit("setUserWalletTransactionData", userWalletTransaction);
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
  },
};
</script>
