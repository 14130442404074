<template>
  <div class="">
    <DashboardNavbar
      :btnAddTitle="$t('UserWalletTransactions.add')"
      :btnAddName="'UserWalletTransactionAdd'"
      :btnAddStatus="checkPrivilege(hasUserWalletTransactionAdd())"
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <template>
          <div class="my-card row custom-card">
            <span class="my-card-title">{{ $t("Users.data") }}</span>
            <DataLabelGroup
              :className="'col-md-6'"
              :value="userWalletTransactions.userData.userNameCurrent"
              :title="$t('Users.name')"
              :imgName="'user.svg'"
            />
            <DataLabelGroup
              :className="'col-md-6 phone-number'"
              :value="userWalletTransactions.userData.userPhoneWithCC"
              :title="$t('Users.phone')"
              :imgName="'phone.svg'"
            />
            <DataLabelGroup
              :className="'col-md-6 phone-number'"
              :value="
                userWalletTransactions.userData.userWalletData
                  .userWalletBalanceWithCurrency
              "
              :title="$t('UserWalletTransactions.balance')"
              :imgName="'money.svg'"
            />
            <DataLabelGroup
              :className="'col-md-6 phone-number'"
              :value="
                userWalletTransactions.userData.userWalletData
                  .userWalletDebtWithCurrency
              "
              :title="$t('UserWalletTransactions.debt')"
              :imgName="'money.svg'"
            />
          </div>
        </template>
        <UserWalletTransactionTable
          class="animate__animated animate__bounceIn"
          v-on:setUserWalletTransactionData="
            userWalletTransactions.userWalletTransaction.fillData($event)
          "
          :userWalletTransactionsData="
            userWalletTransactions.userWalletTransactionsData
          "
          :defaultImg="userWalletTransactions.userWalletTransaction.defaultImg"
          :filterData="userWalletTransactions.filterData"
        />

        <ActionsData
          :actionsData="userWalletTransactions.userWalletTransaction"
        />
        <!-- <UserWalletTransactionInfo
          :userWalletTransaction="userWalletTransactions.userWalletTransaction"
        /> -->

        <CustomBottomSheetQRCode
          :refName="'UserWalletTransactionQRCode'"
          :code="userWalletTransactions.userWalletTransaction.fullCode"
          :codeTitle="$t('UserWalletTransactions.code')"
          :name="
            userWalletTransactions.userWalletTransaction
              .userWalletTransactionValueWithCurreny
          "
          :nameTitle="$t('general.value')"
          :nameIcon="'money.svg'"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="userWalletTransactions.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <UserWalletTransactionFilter
        :theFilterData="userWalletTransactions.filterData"
        v-on:search="search($event)"
      />

      <UserWalletTransactionAdd
        :userWalletTransaction="userWalletTransactions.userWalletTransaction"
        :filterData="userWalletTransactions.filterData"
        v-on:refresh="getAllUserWalletTransactions()"
      />
    </div>
  </div>
</template>

<script>
import { STATUS, VIEW_TYPES } from "./../../../../utils/constants";
import DashboardNavbar from "./../../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../../components/general/PreLoader.vue";
import DataLabelGroup from "./../../../../components/general/DataLabelGroup.vue";
import ExceptionWithImg from "./../../../../components/general/ExceptionWithImg.vue";
import ActionsData from "./../../../../components/general/ActionsData.vue";
import CustomBottomSheetQRCode from "./../../../../components/general/CustomBottomSheetQRCode.vue";
import CustomPagination from "./../../../../components/general/CustomPagination.vue";
import UserWalletTransactionAdd from "./components/UserWalletTransactionAdd.vue";
import UserWalletTransactionTable from "./components/UserWalletTransactionTable.vue";
// import UserWalletTransactionInfo from "./components/UserWalletTransactionInfo.vue";
import UserWalletTransactionFilter from "./components/UserWalletTransactionFilter.vue";
import UserWalletTransactions from "./../../../../models/settings/settingsOthers/userWalletTransactions/UserWalletTransactions";
import apiUserWalletTransaction from "./../../../../api/userWalletTransaction";
import { getDialogOfActivationTypes } from "./../../../../utils/dialogsOfConstantsLists";
import generalMixin from "./../../../../utils/generalMixin";
import generalSettingsMixin from "./../../../../utils/generalSettingsMixin";
import { checkPrivilege } from "./../../../../utils/functions";
import { hasUserWalletTransactionAdd } from "./../../../../utils/privilegeHelper";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    DataLabelGroup,
    ExceptionWithImg,
    ActionsData,
    CustomBottomSheetQRCode,
    CustomPagination,
    UserWalletTransactionAdd,
    UserWalletTransactionTable,
    // UserWalletTransactionInfo,
    UserWalletTransactionFilter,
  },
  computed: {
    hasData() {
      return this.userWalletTransactions.userWalletTransactionsData.length != 0;
    },
  },
  props: ["userToken"],
  data() {
    return {
      userWalletTransactions: new UserWalletTransactions(),
      VIEW_TYPES,
      activationTypesTokenOptions: getDialogOfActivationTypes(),
    };
  },
  methods: {
    checkPrivilege,
    hasUserWalletTransactionAdd,
    changePagination(pagination) {
      this.userWalletTransactions.filterData.fillData(pagination);
      this.getAllUserWalletTransactions();
    },

    search(data) {
      this.userWalletTransactions.filterData.fillData(data);
      this.getAllUserWalletTransactions();
    },
    async getAllUserWalletTransactions() {
      this.isLoading = true;
      try {
        this.userWalletTransactions.userWalletTransactionsData = [];
        const response = await apiUserWalletTransaction.getAll(
          this.userWalletTransactions.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.userWalletTransactions.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.userWalletTransactions.filterData.userToken = this.userToken;
    this.getAllUserWalletTransactions();
  },
};
</script>
<style lang="scss" scoped>
.custom-card {
  margin: 10px !important;
  width: calc(100% - 20px) !important;
}
</style>
